import { FCX } from "@datassential/platform-ui-lib";
import './PermissionOverlay.scss';

const PermissionOverlay: FCX<{
    keyword: string;
    onContactUsClick: () => void;
}> = ({
    keyword,
    onContactUsClick,
}) => {
    return (
        <div className="FoodPermissionOverlay">
            <div className="FoodPermissionOverlay__content">
                <div>Insight about <b>{keyword}</b> are available.</div>
                <div>Unlock this food profile by contacting us today.</div>
                <div className="FoodPermissionOverlay__footer">
                    <div
                        className="FoodPermissionOverlay__button"
                        onClick={onContactUsClick}
                    >
                        Contact us
                    </div>
                    &nbsp;to learn more
                </div>
            </div>
        </div>
    );
}

export default PermissionOverlay;