/* eslint-disable import/no-anonymous-default-export */
export default {
    search: `${process.env.REACT_APP_API}AutoSuggest/GetSearch`,
    'mtd-chart-total-us': `${process.env.REACT_APP_API}Data/GetMenuTrendsPenetration`,
    'mtd-chart': `${process.env.REACT_APP_API}Data/GetMtdChart`,
    'menu-items': `${process.env.REACT_APP_API}Data/GetMenuItemsViewBlock`,
    'menu-table': `${process.env.REACT_APP_API}Data/GetMenuItems`,
    'local-indices': `${process.env.REACT_APP_API}Data/GetProductIndexByMetroState`,
    'mac-instant-chart': `${process.env.REACT_APP_API}Data/GetMacsAndInstantCharts`,
    'consumer-appeal': `${process.env.REACT_APP_API}Data/GetFlavorInterest`,
    'whats-new': `${process.env.REACT_APP_API}WhatsNew/GetWhatsNewStatusV2`,
    'download-report': `${process.env.REACT_APP_API}Export/GenerateFoodProfileReport`,
    'report-list': `${process.env.REACT_APP_API}ReportsV2/ReportsLoadNext`,
    'trendspottings-list': `${process.env.REACT_APP_API}Trendspottings/TrendspottingLoadNext`,
    'download-snapshots': `${process.env.REACT_APP_API}SnapshotsV2/SnapshotsLoadNext`,
    'food tab chart section data': `${process.env.REACT_APP_API}data/GetPenetrationAndFlavourInterest`,
    'food tab widgets section data': `${process.env.REACT_APP_API}data/GetDocumentsAndInstantCharts`,
    'food tab data': `${process.env.REACT_APP_API}api/NewProfile/GetSummaryData`,
    'food tab paired data': `${process.env.REACT_APP_API}api/NewProfile/GetPairedFlavors`,
    'food tab widgets data': `${process.env.REACT_APP_API}api/NewProfile/GetWidgetsData`,
    'food tab haiku data': `${process.env.REACT_APP_API}api/NewProfile/GetPenetrationPrediction`,
    'old places': `${process.env.REACT_APP_API}data/GetPlaces`,
    'download chain reports': `${process.env.REACT_APP_API}api/NewChain/GenerateReportsTask`,
    places: `${process.env.REACT_APP_API}api/NewChain/GetPlaces`,
    'offering chains': `${process.env.REACT_APP_API}api/NewChain/GetOfferingChains`,
    'offering chains + items': `${process.env.REACT_APP_API}api/NewChain/GetPlacesWithMenuItems`,
    documents: `${process.env.REACT_APP_API}data/GetArticlesViewBlock`,
    'view-document': `${process.env.REACT_APP_API}data/GetDocumentInfo`,
    emails: `${process.env.REACT_APP_API}emails/GetEmailsAndGroups`,
    'post-share': `${process.env.REACT_APP_API}emails/ShareLinkRequest`,
    'active tabs': `${process.env.REACT_APP_API}data/GetDocumentsExists`,
    'download places report': `${process.env.REACT_APP_API}Export/PrepareChainsReportTask`,
    'download offers report': `${process.env.REACT_APP_API}Export/WhoOffersItReport`,
    'top chains': `${process.env.REACT_APP_API}TopChains`,
    'top chains get all': `${process.env.REACT_APP_API}api/NewChain/GetAll`,
    'upload documents': `${process.env.REACT_APP_API}DocumentsV2/SaveFile`,
    chop: `${process.env.REACT_APP_API}ArticlesDownload/DownloadChop`,
    'save project': `${process.env.REACT_APP_API}Projects/AddProject`,
    'get projects': `${process.env.REACT_APP_API}Projects/GetAll`,
    'get project': `${process.env.REACT_APP_API}Projects/GetById`,
    'edit project': `${process.env.REACT_APP_API}Projects/EditProject`,
    'delete project': `${process.env.REACT_APP_API}Projects/DeleteProject`,
    'get doctypes and users': `${process.env.REACT_APP_API}DocumentsV2/GetGroupsUsersAndCompaniesList`,
    'chain map': `${process.env.REACT_APP_API}Chain/GetMapCoords`,
    'chain info': `${process.env.REACT_APP_API}Chain/ChainDetails`,
    'chain profile': `${process.env.REACT_APP_API}api/NewChain/GetProfileData`,
    'get menu gaps': `${process.env.REACT_APP_API}chain/GetGapPopUpData/`,
    'get chain lto': `${process.env.REACT_APP_API}api/NewChain/GetLtoData`,
    'get chain lto old': `${process.env.REACT_APP_API}chain/GetChainLTOs`,
    'get document type': `${process.env.REACT_APP_API}DocumentsV2/GetDocumentType`,
    'get document types': `${process.env.REACT_APP_API}DocumentsV2/GetDocumentTypes`,
    'get documents search': `${process.env.REACT_APP_API}DocumentsV2/GetDocumentsByQuery`,
    'save documents': `${process.env.REACT_APP_API}DocumentsV2/Save`,
    'get document share settings': `${process.env.REACT_APP_API}DocumentsV2/GetWhoCanViewAndEdit`,
    'mac-details': `${process.env.REACT_APP_API}UserMac/Edit`,
    'mac-details-save-bg': `${process.env.REACT_APP_API}Admin/Mac/SaveBackgroundUserEnv`,
    'mac-details-reset-bg': `${process.env.REACT_APP_API}Admin/Mac/DeleteMacCustomImage`,
    'mac-menu-examples': `${process.env.REACT_APP_API}Admin/Mac/GetChartData`,
    'mac-download-ppt': `${process.env.REACT_APP_API}UserMac/GenerateUserTemplate`,
    'mac-download-image': `${process.env.REACT_APP_API}UserMac/GenerateImage`,
    'save note': `${process.env.REACT_APP_API}ChainNotes/SaveNote`,
    'delete note': `${process.env.REACT_APP_API}ChainNotes/DeleteNote`,
    'chain insights': `${process.env.REACT_APP_API}chain/GetChainCompetitorsData`,
    'trendspottings-by-family': `${process.env.REACT_APP_API}data/GetDocumentsByPublicationFamily`,
    'chain generate report': `${process.env.REACT_APP_API}Chain/GenerateReportsTask`,
    'chain get competitors': `${process.env.REACT_APP_API}Chain/GetMenuCompetitors`,
    'get instantCharts': `${process.env.REACT_APP_API}InteractiveListData/GetInteractiveList`,
    'get-instantCharts-report': `${process.env.REACT_APP_API}InteractiveListData/GetInteractiveListDataTable`,
    'packages get current': `${process.env.REACT_APP_API}package/getCurrentPackage`,
    'packages add item': `${process.env.REACT_APP_API}package/addPackageItem`,
    'packages remove item': `${process.env.REACT_APP_API}package/removePackageItem`,
    'packages close package': `${process.env.REACT_APP_API}package/closeCurrentPackage`,
    'packages share': `${process.env.REACT_APP_API}package/sendPackage`,
    'packages change name': `${process.env.REACT_APP_API}package/changePackageName`,
    'packages get all': `${process.env.REACT_APP_API}package/getAllPackages`,
    'packages set active': `${process.env.REACT_APP_API}package/setActivePackage`,
    'packages delete': `${process.env.REACT_APP_API}package/deletePackage`,
    'export chain detail': `${process.env.REACT_APP_API}Chain/ExportChainDetails`,
    'save map state': `${process.env.REACT_APP_API}Chain/SaveHeatMap`,
    'add package list': `${process.env.REACT_APP_API}package/AddPackageItems`,
    'get chain news': `${process.env.REACT_APP_API}Chain/GetChainNews`,
    'delete document': `${process.env.REACT_APP_API}DocumentsV2/DeleteDocument`,
    'packages check items permissions': `${process.env.REACT_APP_API}package/GetForbiddenPackageItems`,
    'check document unique name': `${process.env.REACT_APP_API}DocumentsV2/CheckDocumentFileNameIsAvailable`,
    'check project unique name': `${process.env.REACT_APP_API}projects/CheckProjectNameIsAvailable`,
    'chain get gap report': `${process.env.REACT_APP_API}Chain/PrepareGapReport`,
    'chain get simple gap report': `${process.env.REACT_APP_API}Chain/PrepareGapSimpleMatrixReport`,
    'chain get list by name': `${process.env.REACT_APP_API}Chain/GetChainsListByName`,
    'chain get MTD permission': `${process.env.REACT_APP_API}api/user/GetPermissions`,
    'request trial': `${process.env.REACT_APP_API}Subscription/RequestSubscription`,
    'request purchase': `${process.env.REACT_APP_API}Subscription/RequestPurchase`,
    'single chain lto': `${process.env.REACT_APP_API}Chain/GetScoresMenuItem`,
    'groups get all': `${process.env.REACT_APP_API}UserGroups/GetAllGroups`,
    'groups remove member': `${process.env.REACT_APP_API}UserGroups/RemoveMember`,
    'groups create group': `${process.env.REACT_APP_API}UserGroups/CreateGroup`,
    'groups edit group': `${process.env.REACT_APP_API}UserGroups/EditGroup`,
    'groups leave group': `${process.env.REACT_APP_API}UserGroups/LeaveGroup`,
    'groups delete group': `${process.env.REACT_APP_API}UserGroups/DeleteGroup`,
    'groups add members': `${process.env.REACT_APP_API}UserGroups/AddMembersToGroup`,
    'groups check name uniqueness': `${process.env.REACT_APP_API}UserGroups/CheckGroupNameIsAvailable`,
    'groups rename group': `${process.env.REACT_APP_API}UserGroups/RenameGroup`,
    'send email reconfirmation': `${process.env.REACT_APP_API}Account/SendReConfirmEmailFromClient`,
    'get reconfirmation data': `${process.env.REACT_APP_API}User/GetExpireInfo`,
    'videos': `${process.env.REACT_APP_API}Videos/Get`,
    'mark video as watched': `${process.env.REACT_APP_API}Videos/MarkVideoAsWatched`,
    'close ad': `${process.env.REACT_APP_API}Account/HideAd`,
    'analytics api': `${process.env.REACT_APP_ANALYTICS_API}StatCollector/Save`,
    'haiku ad close log': `${process.env.REACT_APP_API}Account/Log`,
    'request custom research': `${process.env.REACT_APP_API}Subscription/RequestCustomResearch`,

    'get who offers menu items': `${process.env.REACT_APP_API}data/GetWhoOffersIt`,
    'send help request': `${process.env.REACT_APP_API}Mail/SendHelpRequest`,
    'get users by company': `${process.env.REACT_APP_API}User/GetByCompany`,
    'get users with status': `${process.env.REACT_APP_API}User/GetWithStatus`,
    'impersonate': `${process.env.REACT_APP_API}Account/Impersonate`,
    'unimpersonate': `${process.env.REACT_APP_API}Account/Unimpersonate`,
    'enable agency mode': `${process.env.REACT_APP_API}Account/EnableAgencyMode`,
    'disable agency mode': `${process.env.REACT_APP_API}Account/DisableAgencyMode`,
    'send mail with pocket snap link': `${process.env.REACT_APP_API}Mail/SendLinkForPocketSnap`,
    'get chain contacts': `${process.env.REACT_APP_API}api/Contact/Get`,
    'edit chain contact': `${process.env.REACT_APP_API}api/Contact/Edit`,
    'create chain contact': `${process.env.REACT_APP_API}api/Contact/Create`,
    'remove chain contact': `${process.env.REACT_APP_API}api/Contact/Delete`,
    'get social links': `${process.env.REACT_APP_API}api/NewChain/GetSocialBlockData`,
    'get all chain notes': `${process.env.REACT_APP_API}api/NewChain/GetAllChainNotes`,
    'request for add contact': `${process.env.REACT_APP_API}api/Contact/RequestForAdd`,
    'request for change contact': `${process.env.REACT_APP_API}api/Contact/RequestForChange`,
    'get notes by chain id': `${process.env.REACT_APP_API}api/NewChain/GetNotes`,
    'remove chain note': `${process.env.REACT_APP_API}api/NewChain/DeleteNote`,
    'save chain note': `${process.env.REACT_APP_API}api/NewChain/SaveNote`,
    'get brand data': `${process.env.REACT_APP_API}api/NewChain/GetBrandData`,
    'chain competitors data': `${process.env.REACT_APP_API}api/NewChain/GetCompetitorsData`,
    'get chain menu data': `${process.env.REACT_APP_API}api/NewChain/GetMenuItems`,
    'unlock request': `${process.env.REACT_APP_API}api/NewChain/ContactUsRequest`,
    'export chains list': `${process.env.REACT_APP_API}api/NewChain/GetChainsReport`,
    'food profile lto': `${process.env.REACT_APP_API}api/NewProfile/GetLtoData`,
    'reportpro new releases': `${process.env.REACT_APP_API}api/PublicationGrandPrix/GetNewReportProReleases`,
    'get instant charts list': `${process.env.REACT_APP_API}api/InstantCharts`,
    'get instant chart': `${process.env.REACT_APP_API}api/InstantCharts/:id`,
    'export instant chart ppt': `${process.env.REACT_APP_API}MenuTrendsExport/GetPptReport`,
    'export instant chart xls': `${process.env.REACT_APP_API}MenuTrendsExport/GetExcelReport`,
    'get faq': `${process.env.REACT_APP_API}api/faq/get`,
    'get feature flags': `${process.env.REACT_APP_API}api/FeatureFlags/get`,
    'get global user data': `${process.env.REACT_APP_API}api/User/GetPageData`,
    'get food profile summary': `${process.env.REACT_APP_API}api/NewProfile/GetMenuTrendsAndFlavorData`,
};
