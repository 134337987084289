import http from '../fetch';
import { useData } from "@hooks/useData";
import { API__FoodProfileMenuTrendsAndFlavorDataViewModel } from "@apiSchema";
import { FoodProfileSummary } from "@models/NewFoodProfileSummary";

async function getFoodProfileSummary(word: string) {
    const data: API__FoodProfileMenuTrendsAndFlavorDataViewModel = await http.get('get food profile summary', { word });
    return {
        summary: new FoodProfileSummary({ apiModel: data }),
    };
}

export function useFoodProfileSummary(word: string) {
    return useData({
        summary: new FoodProfileSummary(),
    }, getFoodProfileSummary, word);
}