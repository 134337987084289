import { FCX, ID, Tooltip } from "@datassential/platform-ui-lib";
import { Link } from "react-router-dom";
import { routeNames } from "@core/routeNames";
import './PipelineMenu.scss';
import cn from "classnames";

export type FoodPipelineStage = 'inception' | 'adoption' | 'proliferation' | 'ubiquity';

export interface IStage {
    id: ID;
    stage: FoodPipelineStage;
    name: string;
    isActive: boolean;
    words: Array<{
        id: ID;
        name: string;
    }>;
}

const FoodPipelineMenu: FCX<{
    data: IStage[];
    categoryName: string;
}> = ({
    data,
    categoryName,
}) => {
    return (
        <div className="FoodPipelineMenu">
            {data.map((item) => (
                <div
                    key={item.id}
                    className={cn(
                        "FoodPipelineMenu__item",
                        item.isActive && 'is-active',
                        `stage-${item.stage}`
                    )}
                    data-tooltip-id={`mac-stage-${item.stage}`}
                >
                    <div className="FoodPipelineMenu__item-bullet"/>
                    <div className="FoodPipelineMenu__item-title">{item.name}</div>
                    <Tooltip
                        id={`mac-stage-${item.stage}`}
                        place="bottom"
                        clickable
                    >
                        <div className="FoodPipelineMenu__hover-popup">
                            <div>Other {categoryName} in the {item.name.toUpperCase()} stage:</div>
                            <br/>
                            <div className="FoodPipelineMenu__hover-list">
                                {item.words.map((word) => (
                                    <Link
                                        key={word.id}
                                        className="FoodPipelineMenu__hover-item"
                                        to={{
                                            pathname: routeNames.food,
                                            search: `?q=${encodeURIComponent(word.name.toLowerCase())}`
                                        }}
                                    >
                                        {word.name}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </Tooltip>
                </div>
            ))}
        </div>
    );
}

export default FoodPipelineMenu;