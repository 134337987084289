import { AppId, FCX, ProductLogo } from "@datassential/platform-ui-lib";
import Page from "@containers/Page/Page";
import PageContent from "@containers/PageContent/PageContent";
import FoodSummary from "@pages/Food/components/Summary/Summary";
import './Food.scss';
import { useFoodProfileSummary } from "@apiHooks/useFoodProfileSummary";
import useQueryParam from "@hooks/useQueryParam";

const Food: FCX = () => {
    const word = useQueryParam('q');
    const {
        data: {
            summary,
        },
        isLoading,
    } = useFoodProfileSummary(word);
    return (
        /* @ts-ignore */
        <Page>
            <PageContent>
                <div className="Food">
                    {isLoading && (
                        <div className="spinner spinner-blink">
                            <ProductLogo appId={AppId.Snap} type={'regular'}/>
                        </div>
                    )}
                    {!isLoading && <FoodSummary data={summary}/>}
                </div>
            </PageContent>
        </Page>
    );
}

export default Food;