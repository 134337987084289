import './Summary.scss';
import {
    AppId,
    Button,
    ButtonType,
    equalInLC,
    FCX,
    getFormattedThousand,
    ID, Tooltip,
    usePlatformContext
} from "@datassential/platform-ui-lib";
import { FoodProfileSummary } from "@models/NewFoodProfileSummary";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { FiDownload, FiExternalLink } from "react-icons/fi";
import { IconPackages } from "@icons";
import http from "@core/fetch";
import { sanitizeUrl } from "@helpers";
import { toast } from "react-toastify";
import cn from "classnames";
import PipelineMenu, { FoodPipelineStage, IStage } from "@pages/Food/components/PipelineMenu/PipelineMenu";
import { API__PenetrationSuggestedMacWordViewModel } from "@apiSchema";
import { addFileToPackage } from "@actions/packages.actions";
import { useDispatch } from "react-redux";
import Chart from "@pages/Food/components/Chart/Chart";
import AutoSizer from "react-virtualized-auto-sizer";
import Tabs from "@pages/Food/components/Tabs/Tabs";
import { iYearPoint } from "@pages/Food/components/Chart/Chart.helpers";
import { Link } from "react-router-dom";
import { routeNames } from "@core/routeNames";
import PermissionOverlay from "@pages/Food/components/PermissionOverlay/PermissionOverlay";
import { PermissionLevelEnum } from "@models/PermissionSection";
import { requestTrialByFeature } from "@actions/page";
import AnimatedNumber from "@pages/Food/components/AnimatedNumber";

const ExternalLinkIcon: FCX<{
    size: number;
}> = ({
    size,
}) => (
    <FiExternalLink style={{
        width: size,
        height: size,
        marginLeft: 8,
        marginBottom: -2,
    }}/>
);

const FoodSummary: FCX<{
    data: FoodProfileSummary;
}> = ({
    data: {
        id,
        name,
        data,
    },
}) => {
    const dispatch = useDispatch();
    const requestTrial: (f: string, hideNotification?: boolean) => any = (featureName, hideNotification) => {
        if (!hideNotification) {
            toast.info('Request has been sent');
        }
        return dispatch(requestTrialByFeature(featureName));
    };

    const {
        userInfo: { products }
    } = usePlatformContext();

    const mtProduct = products[AppId.MenuTrends];

    const [activeSliceKey, setActiveSliceKey] = useState<ID>(null);
    const [isReportLoading, setIsReportLoading] = useState(false);
    const {
        filterDescription,
        chainsMoreCommon,
        consumerMostCommon,
        downloadLink,
        chainsCount,
        loveLikePct,
        consumerPreferencesPermissionLevel,
        slices,
        macStage,
        suggestedMacKeywords,
        cpLink,
    } = data.data;

    useEffect(() => {
        if (slices.length > 0) {
            setActiveSliceKey(slices[0].id);
        }
    }, [slices]);

    const activeSlice = useMemo(
        () => slices.find(slice => slice.id === activeSliceKey),
        [activeSliceKey, slices]
    );

    const downloadReport = useCallback(
        () => {
            setIsReportLoading(true);
            http.downloadFileXHRFromUrl('GET', downloadLink)
                .then(() => {
                    toast.success('Report downloaded successfully');
                })
                .catch(() => {
                    toast.error('Failed to download report');
                })
                .finally(() => {
                    setIsReportLoading(false);
                });
        },
        [downloadLink]
    );

    const addToPackage = useCallback(() => {
        toast.success('Item has been added to package');
        dispatch(addFileToPackage({
            itemId: id,
            itemDocumentType: 'foodProfile',
        }));
    }, [dispatch, id]);

    const bulletsList1 = useMemo(
        (): ReactNode[] => {
            const result: ReactNode[] = [];

            if (activeSlice) {
                result.push(<>
                    on<b>&nbsp;
                    <AnimatedNumber
                        value={activeSlice.penetration}
                        valueFormatter={v => v.toFixed(1)}
                    />
                    %&nbsp;</b>of {activeSlice.name} menus
                </>);
                result.push(<>
                    historic growth: 12-month<b>&nbsp;
                    <AnimatedNumber
                        value={activeSlice.oneYearTrend}
                        valueFormatter={v => `${v > 0 ? '+' : ''}${v.toFixed(1)}`}
                    />
                    %</b>,
                    4-year<b>&nbsp;
                    <AnimatedNumber
                        value={activeSlice.fourYearTrend}
                        valueFormatter={v => `${v > 0 ? '+' : ''}${v.toFixed(1)}`}
                    />
                    %</b>
                </>);
                result.push(<>
                    predicted growth:
                    4-year<b>&nbsp;
                    <AnimatedNumber
                        value={activeSlice.fourYearFutureTrend}
                        valueFormatter={v => `${v > 0 ? '+' : ''}${v.toFixed(1)}`}
                    />
                    %</b>
                </>);
            }

            if (chainsCount > 0) {
                result.push(
                    <div className="FoodSummary__bullet-inner">
                        <div>
                            on the menu at&nbsp;
                            <Link
                                to={`${routeNames.places}?q=${encodeURIComponent(name)}`}
                                target={'_blank'}
                            >
                                <b>{getFormattedThousand(chainsCount)} chains</b>
                                <ExternalLinkIcon size={20}/>
                            </Link>
                        </div>
                        <div className="FoodSummary__bullet-sub">
                            {chainsMoreCommon
                                ? (<>more common at {chainsMoreCommon} restaurants</>)
                                : 'found equally at chain and independent restaurants'
                            }
                        </div>
                    </div>
                );
            }

            return result;
        },
        [chainsCount, activeSlice, chainsMoreCommon]
    );

    const bulletsList2 = useMemo(
        (): ReactNode[] => {
            const result: ReactNode[] = [];

            if (loveLikePct > 0) {
                result.push(<>
                    <b>{Math.floor(loveLikePct)}%&nbsp;</b>of consumers love or like it
                    {consumerPreferencesPermissionLevel === PermissionLevelEnum.Full && (
                        <a
                            href={cpLink}
                            target={'_blank'}
                            rel={'noreferrer'}
                        >
                            <ExternalLinkIcon size={20}/>
                        </a>
                    )}
                </>);
            }

            if (consumerMostCommon.length > 0) {
                result.push(<>most popular with<b>&nbsp;{consumerMostCommon.join(', ')}&nbsp;</b> consumers</>);
            } else {
                result.push('equally popular with all consumer types');
            }

            return result;
        },
        [loveLikePct, consumerMostCommon, consumerPreferencesPermissionLevel]
    );

    const macStageData = useMemo(
        (): IStage[] => {
            const getStageByName = (stage: string) => suggestedMacKeywords.find(i => equalInLC(i.macStageName, stage))!;
            const mapStage = (stageData: API__PenetrationSuggestedMacWordViewModel, type: FoodPipelineStage): IStage => ({
                id: stageData.macStageId,
                stage: type,
                name: stageData.macStageName,
                isActive: equalInLC(macStage, stageData.macStageName),
                words: stageData.words.slice(0, 7).map(word => ({
                    id: word.id,
                    name: word.name,
                })),
            });
            const inception = getStageByName('inception');
            const adoption = getStageByName('adoption');
            const proliferation = getStageByName('proliferation');
            const ubiquity = getStageByName('ubiquity');

            return [
                mapStage(inception, 'inception'),
                mapStage(adoption, 'adoption'),
                mapStage(proliferation, 'proliferation'),
                mapStage(ubiquity, 'ubiquity'),
            ];
        },
        [suggestedMacKeywords, macStage]
    );

    const slicesItems = useMemo(
        () => {
            return slices.map(slice => ({
                id: slice.id,
                name: `${slice.name} ${slice.penetration.toFixed(1)}%`,
            }));
        },
        [slices]
    );

    const chartData = useMemo(
        (): iYearPoint[][] => {
            return slices.map(slice => [
                ...slice.points.years.map(point => ({
                    value: point.value,
                    label: point.label,
                })),

            ]);
        },
        [slices]
    );

    const hasAccess = data.permissionLevel !== PermissionLevelEnum.None;

    return (
        <div className={cn(
            "FoodSummary appears-top",
            !hasAccess && 'is-blocked'
        )}>
            <div className="FoodSummary__aside">
                <div className="FoodSummary__aside-content">
                    <a
                        className="FoodSummary__title p appears-left del-1"
                        href={sanitizeUrl(`${mtProduct.url}${process.env.REACT_APP_MT_BYO_LINK}${encodeURIComponent(name)}`)}
                        target={'_blank'}
                        rel={'noreferrer'}
                    >
                        {name}
                        {hasAccess && (<div data-tooltip-id="mt-link">
                            <ExternalLinkIcon
                                size={24}
                            />
                            <Tooltip id="mt-link" place="bottom">
                                Analyze <b>{name}</b> in Menu Trends
                            </Tooltip>
                        </div>)}
                    </a>
                    {filterDescription ? (
                        <div className="p appears-left del-2 FoodSummary__blur">
                            menu data filtered on {filterDescription}
                        </div>
                    ) : (<div className="p appears-left del-2">&nbsp;</div>)}
                    <ul className="FoodSummary__blur">
                        {bulletsList1.map((bullet, index) => (
                            <li key={index} className={`p appears-left del-${index + 2}`}>
                                <div>{bullet}</div>
                            </li>
                        ))}
                    </ul>
                    {bulletsList1.length > 0 && bulletsList2.length > 0 && (
                        <div className={`FoodSummary__delimiter appears-left del-${bulletsList1.length + 2}`}/>
                    )}
                    <ul className="FoodSummary__blur">
                        {bulletsList2.map((bullet, index) => (
                            <li key={index} className={`p appears-left del-${index + bulletsList1.length + 3}`}>
                                <div>{bullet}</div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="FoodSummary__aside-footer FoodSummary__blur">
                    <Button
                        modifiers={['no-rem']}
                        type={ButtonType.Button}
                        onClick={downloadReport}
                        style={{ width: 160, }}
                        isDisabled={isReportLoading}
                        className={cn(
                            `appears-bottom del-${3 + bulletsList1.length + bulletsList2.length}`,
                            isReportLoading && 'FoodSummary__button-loading'
                        )}
                    >
                        {!isReportLoading && (<>
                            Download <FiDownload style={{ marginLeft: '5px', width: 20, height: 20 }}/>
                        </>)}
                        {isReportLoading && 'loading...'}
                    </Button>
                    {/*<PackageAddWrapper>*/}
                    <Button
                        modifiers={['no-rem']}
                        onClick={addToPackage}
                        type={ButtonType.Button}
                        style={{ width: 160, }}
                        className={cn(
                            `appears-bottom del-${4 + bulletsList1.length + bulletsList2.length}`,
                        )}
                    >
                        Package
                        <IconPackages style={{
                            filter: 'brightness(0) saturate(100%)',
                            marginLeft: '5px',
                        }}/>
                    </Button>
                    {/*</PackageAddWrapper>*/}
                </div>
            </div>
            <div className="FoodSummary__main">
                <div className="FoodSummary__main-header appears-top del-10 FoodSummary__blur">
                    <PipelineMenu data={macStageData} categoryName="no category so far"/>
                </div>
                <div className="FoodSummary__main-content appears-right del-11">
                    <AutoSizer>
                        {({ width, height }) => (
                            <Chart
                                width={width}
                                height={height}
                                data={chartData}
                                chartsTitles={slices.map(s => s.name)}
                                printValue={(v) => `${v.toFixed(1)}%`}
                                activeChartIndex={0}
                                setActiveChartIndex={() => {
                                }}
                                testId="chartFoodSummary"
                            />
                        )}
                    </AutoSizer>
                </div>
                <div className="FoodSummary__main-footer appears-bottom del-10 FoodSummary__blur">
                    <Tabs
                        data={slicesItems}
                        activeItemId={activeSliceKey}
                        setActiveItemId={setActiveSliceKey}
                    />
                </div>
            </div>
            {!hasAccess && (
                <PermissionOverlay
                    keyword={name}
                    onContactUsClick={() => requestTrial('mtd')}
                />
            )}
        </div>
    );
}

export default FoodSummary;