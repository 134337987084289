/* @ts-ignore */
import { animated, useSpring } from 'react-spring';
import { memo, useEffect, useState } from 'react';
import { FCX, getFormattedThousand } from "@datassential/platform-ui-lib";

const AnimatedNumber: FCX<{
    value: number;
    durationMs?: number;
    valueFormatter?: (value: number) => string;
}> = ({
    value,
    style,
    durationMs = 100,
    valueFormatter,
}) => {
    const [previousValue, setPreviousValue] = useState(0);

    const { number } = useSpring({
        from: { number: previousValue},
        number: value,
        delay: 0,
        config: { duration: durationMs },
    });

    useEffect(() => {
        return () => {
            setPreviousValue(value);
        };
    }, [value]);

    return (
        <animated.span
            style={{
                ...style,
                fontFeatureSettings: 'tnum',
                fontVariantNumeric: 'tabular-nums',
            }}
        >
            {/* @ts-ignore */}
            {number.to((v) => (valueFormatter ?? getFormattedThousand)(v))}
        </animated.span>
    )
}

export default memo(AnimatedNumber);