import React, { FC } from "react";
import { iViewBoxProps, PointType } from "./Chart.helpers";
import cn from "classnames";

interface iDot {
    x: number;
    y: number;
    label: string;
    type: PointType;
    isHoverable?: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}


const ChartDots: FC<{
    data: iDot[];
    viewport: iViewBoxProps;
}> = (
    {
        data,
        viewport,
    }
) => {
    return (
        <div
            className="Chart__dots FoodSummary__blur"
            style={{
                top: viewport.padding.top,
                left: viewport.padding.left,
                width: viewport.width,
                height: viewport.height,
            }}
            data-testid={`textChartDots`}
        >
            {data.map((dot, dotIndex) => (
                <div
                    key={dotIndex}
                    className="ChartDot"
                    style={{
                        transform: `translate(${dot.x}px, ${dot.y}px)`
                    }}
                >
                    <div
                        onMouseEnter={dot.onMouseEnter}
                        onMouseLeave={dot.onMouseLeave}
                        className={cn('ChartDot__inner', `ChartDot__inner--${dot.type}`, dot.isHoverable && 'is-hoverable')}
                        data-testid={`textChartDot${dotIndex}`}
                    >
                        {dot.label}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ChartDots;