import { FCX, ID } from "@datassential/platform-ui-lib";
import { ReactNode } from "react";
import cn from "classnames";
import './Tabs.scss';

export interface ITab {
    id: ID;
    name: ReactNode;
}

const FoodTabs: FCX<{
    data: ITab[];
    activeItemId: ID;
    setActiveItemId: (id: ID) => void;
}> = ({
    data,
    activeItemId,
    setActiveItemId,
}) => {
    return (
        <div className="FoodTabs">
            {data.map((item) => (
                <div
                    key={item.id}
                    className={cn(
                        "FoodTabs__item",
                        item.id === activeItemId && ' is-active',
                    )}
                    onClick={() => setActiveItemId(item.id)}
                >
                    {item.name}
                </div>
            ))}
        </div>
    );
};

export default FoodTabs;